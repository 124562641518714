import { Component, HostBinding, Input, ViewEncapsulation, booleanAttribute, input } from '@angular/core';

@Component({
  selector: 'lay-panel-block',
  templateUrl: './panel-block.component.html',
  styleUrls: ['./panel-block.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class PanelBlockComponent {
  styleClass = input<string>('');
  size = input<'small' | 'default' | 'large' | 'fluid'>('default');
  @Input() isBottomFixed;
  transparent = input(false, { transform: booleanAttribute });

  @HostBinding('class') get sizeClass() {
    return `panel-block is-${this.size()} ${this.styleClass()} ${this.transparent() ? 'is-transparent-background is-borderless' : ''}`;
  }

}

/**
 * Used in Settings Legrand, Settings Lux
 */
