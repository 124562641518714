import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AckLocalResourcesPayload, GetHomeInfoPayload, HomeCoachsData, HomesData, HomeSyncPayload, LoadComparisonDataPayload, LoadComparisonDataSuccessPayload, LoadForecastPayload, LoadForecastSuccessPayload, PlaceUpdate, SetStatePayload, SyncError } from './homes.interface';

// Used for setConfig calls
export class ConfigError extends Error {
  error;
  constructor(error = {}, message?: string) {
    super(message);
    this.error = error;
    this.name = 'ConfigError';
  }
}

@Injectable()
export class HomesService {
  constructor(private http: NgrxHttpClient) {
  }

  getHomes(params): Observable<HomesData> {
    return this.http.get('homesdata', params);
  }

  getHomeCoachs(params): Observable<HomeCoachsData> {
    return this.http.get('gethomecoachsdata', params);
  }

  updateHomePlace(placeUpdate: PlaceUpdate) {
    return this.http.get('updatehomeplace', placeUpdate);
  }

  updateHome(payload) {
    return this.http.post('updatehome', payload);
  }

  getHomeStatus(params: GetHomeInfoPayload): Observable<{home?: HomeSyncPayload, errors?: SyncError[]}> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('home_id', params.home_id);
    if(params.device_types) {
      if (typeof params.device_types === 'string') {
        httpParams = httpParams.append('device_types[]', params.device_types);
      }
      else {
        params.device_types.forEach(device => {
          httpParams = httpParams.append('device_types[]', device);
        });
      }
    }
    if (params.module_ids) {
      params.module_ids.forEach(moduleId => {
        httpParams = httpParams.append('module_ids[]', moduleId);
      });
    }

    return this.http.getSync('homestatus', httpParams)
      .pipe(map(body => body || {}));
  }

  getReadonlyDeviceState(): Observable<{modules: [], errors?: SyncError[]}> {
    return this.http.getSync('getReadonlyDeviceState')
      .pipe(map(body => body || {}));
  }

  getHomeConfig(params: GetHomeInfoPayload): Observable<{ home: HomeSyncPayload, errors?: SyncError[]}> {
    return this.http.getSync('getconfigs', params).pipe(
      map(body => body || {}),
      map((body: {home: HomeSyncPayload, errors: SyncError[]}) => {
        return {
          home: { id: params.home_id, modules: [], rooms: [], ...body.home },
          errors: body.errors || [],
        };
      }),
    );
  }

  setHomeConfig(params): Observable<{errors?: SyncError[]}> {
    return this.http.postSync('setconfigs', params)
      .pipe(
        map((r) => {
          const body = r.body || {};
          if (body.errors && body.errors.length > 0) {
            throw new ConfigError(body.errors);
          }
          else {
            return body;
          }
        })
      );
  }

  setState(params: SetStatePayload, header?: { [key: string]: string }): Observable<{ errors?: SyncError[] }> {
    return this.http.postSync('setstate', params, null, header)
      .pipe(map(r => r.body || {}));
  }

  ackLocalResources(params: AckLocalResourcesPayload, header?: { [key: string]: string }): Observable<{ errors?: SyncError[] }>  {
    return this.http.postSync('ackLocalResources', params, null, header)
      .pipe(map(r => r.body || {}));
  }

  getHomeUsers(homeId: string) {
    const params = {};

    if (homeId) {
      params['home_id'] = homeId;
    }

    return this.http.post('gethomeusers', params)
      .pipe(map(r => r.body || {}));
  }

  generateAdminAccessCode(homeId: string) {
    const params = {
      home_id: homeId
    };

    return this.http.post('generatehomeadminaccesscode', params)
      .pipe(map(r => r.body || {}));
  }

  removeUserAccessToHome(homeId: string, userId: string) {
    const params = {
      home_id: homeId,
      user_id: userId
    };

    // ici faire un set state
    return this.http.post('removeuseraccesstohome', params);
  }

  setHomeData(params) {
    return this.http.post('sethomedata', params);
  }

  getHomeNFA(params: {home_id: string, app_type: string}) {
    return this.http.get('gethomenfa', params);
  }

  removeDeviceFromHome(params: {home_id: string, device_id: string}) {
    return this.http.post('removedevicefromhome', params);
  }

  setThermMode(homeId: string, therm_mode: string, endtime?: number) {
    const params = endtime ? {home_id: homeId, mode: therm_mode, endtime} : {home_id: homeId, mode: therm_mode};

    return this.http.post('setthermmode', params).pipe(
      map(r => r.body || {})
    );
  }

  setThermPoint(params: {home_id:string, room_id: string, mode: string, endtime: number, temp: number}) {
    return this.http.postSync('setthermpoint', params).pipe(
      map(r => r.body || {})
    );
  }

  loadForecast(payload: LoadForecastPayload): Observable<LoadForecastSuccessPayload> {
    return this.http.post('simplifiedfuturemeasure', payload).pipe(
      map(r => r.body || {})
    );
  }

  loadComparisonData(payload: LoadComparisonDataPayload): Observable<LoadComparisonDataSuccessPayload> {
    return this.http.post('gethomecomparisoncharts', payload).pipe(
      map(r => r.body || {})
    );

  }

}
