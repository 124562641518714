import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, asapScheduler, of, scheduled } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import {
  AckLocalResources,
  AckLocalResourcesFailure,
  AckLocalResourcesSuccess,
  EnumHomesActions,
  GetAdminAccessCode,
  GetAdminAccessCodeFailure,
  GetAdminAccessCodeSuccess,
  GetHomeCoachs,
  GetHomeCoachsFailure,
  GetHomeCoachsSuccess,
  GetHomeConfig,
  GetHomeConfigFailure,
  GetHomeConfigSuccess,
  GetHomeNFA,
  GetHomeNFAFailure,
  GetHomeNFASuccess,
  GetHomeStatus,
  GetHomeStatusFailure,
  GetHomeStatusSuccess,
  GetHomeUsers,
  GetHomeUsersFailure,
  GetHomeUsersSuccess,
  GetHomes,
  GetHomesFailure,
  GetHomesSuccess,
  GetReadonlyDeviceState,
  GetReadonlyDeviceStateFailure,
  GetReadonlyDeviceStateSuccess,
  HideSpinner,
  LoadComparisonData,
  LoadComparisonDataFailure,
  LoadComparisonDataSuccess,
  LoadForecast,
  LoadForecastFailure,
  LoadForecastSuccess,
  RemoveDeviceFromHome,
  RemoveDeviceFromHomeFailure,
  RemoveDeviceFromHomeSuccess,
  RemoveUserFromHome,
  RemoveUserFromHomeFailure,
  RemoveUserFromHomeSuccess,
  SelectHome,
  SetAutoMode,
  SetAutoModeFailure,
  SetAutoModeSuccess,
  SetConfig,
  SetConfigFailure,
  SetConfigSuccess,
  SetCoolingMode,
  SetCoolingModeFailure,
  SetCoolingModeSuccess,
  SetHeatingMode,
  SetHeatingModeFailure,
  SetHeatingModeSuccess,
  SetHomeType,
  SetHomeTypeFailure,
  SetHomeTypeSuccess,
  SetState,
  SetStateErrors,
  SetStateFailure,
  SetStateSuccess,
  SetTemperatureControlMode,
  SetTemperatureControlModeFailure,
  SetTemperatureControlModeSuccess,
  SetThermMode,
  SetThermModeFailure,
  SetThermModeSuccess,
  SetThermPoint,
  SetThermPointFailure,
  SetThermPointSuccess,
  ShowErrorModal,
  ShowSpinner,
  UpdateHomeName,
  UpdateHomeNameFailure,
  UpdateHomeNameSuccess,
  UpdateHomePlace,
  UpdateHomePlaceFailure,
  UpdateHomePlaceSuccess
} from './homes.action';

import { GetModulesHomeCoachsFailure, GetModulesHomeCoachsSuccess } from '../modules/modules.action';
import { HomesService } from './homes.service';

import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Store } from '@ngrx/store';
import { SetModulesErrors, SetModulesStatusErrors, UpdateModulesSetConfig, UpdateModulesStatus } from '../modules/modules.action';
import { Module } from '../modules/modules.interface';
import { GetRoomsHomeCoachsFailure, GetRoomsHomeCoachsSuccess, UpdateRoomsSetConfig, UpdateRoomsStatus } from '../rooms/rooms.action';
import { Room } from '../rooms/rooms.interface';
import { SetHomeUser } from '../user/user.action';
import { HomeCoachsData, HomeSyncPayload, HomesData, Place } from './homes.interface';

@Injectable()
export class HomesEffects {
  constructor(
    private homesService: HomesService,
    private actions$: Actions,
    private store: Store<SettingsState>,
  ) { }

  getHomes$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomes>(EnumHomesActions.GetHomes),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.getHomes(action.payload).pipe(
        switchMap((homes: HomesData) => [
          new HideSpinner(),
          new GetHomesSuccess(homes.homes, homes.modules),
          SetHomeUser({user: homes.user}),
        ]),
        catchError(({ error }) => of(new GetHomesFailure(error), new HideSpinner())),
      );
    })
  ));

  getHomeCoachs$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomeCoachs>(EnumHomesActions.GetHomeCoachs),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.getHomeCoachs(action.payload).pipe(
        switchMap((homeCoachs: HomeCoachsData) => [
          new HideSpinner(),
          new GetHomeCoachsSuccess(homeCoachs),
          new GetModulesHomeCoachsSuccess(homeCoachs),
          new GetRoomsHomeCoachsSuccess(homeCoachs)
        ]),
        catchError(({ error }) => of(
          new GetHomeCoachsFailure(error),
          new GetModulesHomeCoachsFailure(error),
          new GetRoomsHomeCoachsFailure(error),
          new HideSpinner())),
      );
    })
  ));

  // @deprecated
  // Select home if only one in hoesdata
  // This should be deleted because it can cause race conditions and is useless
  selectHome$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomesSuccess>(EnumHomesActions.GetHomesSuccess),
    debounceTime(0), // Needed to cancel the race condition
    switchMap((action) => {
      if (action.payload.length === 1) {
        return [new SelectHome(action.payload[0].id)];
      }
      else {
        return EMPTY;
      }
    }),
  ));

  updateHomePlace$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateHomePlace>(EnumHomesActions.UpdateHomePlace),
    switchMap((action: UpdateHomePlace) => {
      return this.homesService.updateHomePlace(action.payload).pipe(
        switchMap((updatedHome: Place) => [
          new UpdateHomePlaceSuccess({ updatedHome, home_id: action.payload.home_id }),
        ]),
        catchError(({ error }) => of(new UpdateHomePlaceFailure(error))),
      );
    })
  ));

  getHomesStatus$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomeStatus>(EnumHomesActions.GetHomeStatus),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.getHomeStatus(action.payload).pipe(
        switchMap(({home, errors}) => [
          new HideSpinner(),
          new SetModulesStatusErrors({ id: action.payload.home_id, errors: errors || [] }),
          new GetHomeStatusSuccess(home || { id: action.payload.home_id, rooms: [], modules: []}),
        ]),
        catchError(({ error }) => of(new GetHomeStatusFailure(error), new HideSpinner())),
      );
    })
  ));

  getReadonlyDeviceState$ = createEffect(() => this.actions$.pipe(
    ofType<GetReadonlyDeviceState>(EnumHomesActions.GetReadonlyDeviceState),
    switchMap(() => {
      return this.homesService.getReadonlyDeviceState().pipe(
        switchMap(({modules, errors}) => [
          new GetReadonlyDeviceStateSuccess(modules ?? [], errors ?? []),
        ]),
        catchError(({ error }) => of(new GetReadonlyDeviceStateFailure(error))),
      );
    })
  ));

  getHomesConfig$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomeConfig>(EnumHomesActions.GetHomeConfig),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.getHomeConfig(action.payload).pipe(
        switchMap(({home, errors}) => [
          new HideSpinner(),
          new SetModulesErrors({ id: action.payload.home_id, errors: errors || [] }),
          new GetHomeConfigSuccess(home || { id: action.payload.home_id, modules: [], rooms: [] })
        ]),
        catchError((error) => {
          return of(new GetHomeConfigFailure(error), new HideSpinner(), new ShowErrorModal());
        }),
      );
    })
  ));

  setHomesConfig$ = createEffect(() => this.actions$.pipe(
    ofType<SetConfig>(EnumHomesActions.SetConfig),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.setHomeConfig(action.payload).pipe(
        switchMap(({errors}) => [
          new SetModulesErrors({id: action.payload.home_id, errors: errors || []}),
          new UpdateModulesSetConfig(action.payload),
          new UpdateRoomsSetConfig(action.payload),
          new HideSpinner(),
          new SetConfigSuccess()
        ]),
        catchError(({ error }) => of(new SetConfigFailure(error), new HideSpinner(), new ShowErrorModal())),
      );
    })
  ));

  getHomeUsers$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomeUsers>(EnumHomesActions.GetHomeUsers),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.getHomeUsers(action.payload).pipe(
        switchMap((users) => [
          new GetHomeUsersSuccess({ id: action.payload, users }),
          new HideSpinner(),
        ]),
        catchError(({ error }) => of(new HideSpinner(), new ShowErrorModal(), new GetHomeUsersFailure(error))),
      );
    })
  ));

  removeUserFromHome$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveUserFromHome>(EnumHomesActions.RemoveUserFromHome),
    switchMap((action) => {
      return this.homesService.removeUserAccessToHome(action.payload.homeId, action.payload.userId).pipe(
        switchMap(() => [
          new RemoveUserFromHomeSuccess({ homeId: action.payload.homeId, userId: action.payload.userId})
        ]),
        catchError(({ error }) => of(new ShowErrorModal(), new RemoveUserFromHomeFailure(error))),
      );
    })
  ));

  updateHomeName$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateHomeName>(EnumHomesActions.UpdateHomeName),
    switchMap( (action) => {
      return this.homesService.updateHome(action.payload).pipe(
        switchMap(() => [
          new UpdateHomeNameSuccess(action.payload)
        ]),
        catchError( ({ error }) => of(new UpdateHomeNameFailure(error), new ShowErrorModal()))
      );
    })
  ));

  getAdminAccessCode$ = createEffect(() => this.actions$.pipe(
    ofType<GetAdminAccessCode>(EnumHomesActions.GetAdminAccessCode),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.generateAdminAccessCode(action.payload).pipe(
        switchMap((res: {admin_access_code: string}) => [
          new GetAdminAccessCodeSuccess({ homeId: action.payload, accessCode: res.admin_access_code }),
          new HideSpinner(),
        ]),
        catchError(({ error }) => of(new HideSpinner(), new ShowErrorModal(), new GetAdminAccessCodeFailure(error))),
      );
    })
  ));

  setState$ = createEffect(() => this.actions$.pipe(
    ofType<SetState>(EnumHomesActions.SetState),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.setState(action.payload, action.header).pipe(
        switchMap((payload) => {
          if (payload.errors) {
            return [
              new SetStateErrors({errors: payload.errors}),
              new HideSpinner(),
            ];
          }
          else {
            const returnPayload = action.payload.home as HomeSyncPayload;
            return [
              new SetStateSuccess(),
              new UpdateModulesStatus(returnPayload),
              new UpdateRoomsStatus(returnPayload),
              new HideSpinner(),
            ];
          }
        }),

        catchError(({ error }) => of(new SetStateFailure(error), new HideSpinner(), new ShowErrorModal())),
      );
    })
  ));

  ackLocalResources$ = createEffect(() => this.actions$.pipe(
    ofType<AckLocalResources>(EnumHomesActions.AckLocalResources),
    switchMap((action) => {
      this.store.dispatch(new ShowSpinner());
      return this.homesService.ackLocalResources(action.payload, action.header).pipe(
        switchMap((payload) => {
          if (payload.errors) {
            return [
              new AckLocalResourcesFailure({errors: payload.errors}),
              new HideSpinner(),
            ];
          }
          else {
            const returnPayload: HomeSyncPayload = {
              id: action.payload.home_id,
              rooms: action.payload.rooms as Room[],
              modules: action.payload.modules as Module[]
            };
            return [
              new AckLocalResourcesSuccess(),
              new UpdateModulesStatus(returnPayload),
              new UpdateRoomsStatus(returnPayload),
              new HideSpinner(),
            ];
          }
        }),

        catchError(({ error }) => of(new AckLocalResourcesFailure(error), new HideSpinner(), new ShowErrorModal())),
      );
    })
  ));

  setTemperatureControlMode$ = createEffect(() => this.actions$.pipe(
    ofType<SetTemperatureControlMode>(EnumHomesActions.SetTemperatureControlMode),
    switchMap((action) => {
      return this.homesService.setHomeData(action.payload).pipe(
        switchMap(() => [
          new SetTemperatureControlModeSuccess(action.payload)
        ]),
        catchError(({ error }) =>
          of(new SetTemperatureControlModeFailure(error))
        )
      );
    })
  ));

  getHomeNFA$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomeNFA>(EnumHomesActions.GetHomeNFA),
    switchMap( (action) => {
      return this.homesService.getHomeNFA(action.payload).pipe(
        switchMap((res) => [
          new GetHomeNFASuccess(res)
        ]),
        catchError( ({error}) =>
          of(new GetHomeNFAFailure(error))
        )
      );
    })
  ));

  setHomeType$ = createEffect(() => this.actions$.pipe(
    ofType(EnumHomesActions.SetHomeType),
    switchMap((action: SetHomeType) => {
      return this.homesService.setHomeData(action.payload).pipe(
        switchMap(() => [
          new SetHomeTypeSuccess(action.payload)
        ]),
        catchError(({ error }) =>
          scheduled(of(new SetHomeTypeFailure(error)), asapScheduler))
      );
    })
  )
  );

  removeDeviceFromHome$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveDeviceFromHome>(EnumHomesActions.RemoveDeviceFromHome),
    switchMap( (action) => {
      return this.homesService.removeDeviceFromHome(action.payload).pipe(
        switchMap((res) => [
          new RemoveDeviceFromHomeSuccess(res)
        ]),
        catchError( ({error}) =>
          of(new RemoveDeviceFromHomeFailure(error))
        )
      );
    })
  ));

  setThermMode$ = createEffect(() => this.actions$.pipe(
    ofType<SetThermMode>(EnumHomesActions.SetThermMode),
    switchMap( (action) => {
      return this.homesService.setThermMode(action.homeId, action.therm_mode, action.endtime).pipe(
        switchMap(() => [
          new SetThermModeSuccess(action.homeId, action.therm_mode, action.endtime)
        ]),
        catchError( ({error}) =>
          of(new SetThermModeFailure(error))
        )
      );
    })
  ));

  setThermPoint$ = createEffect(() => this.actions$.pipe(
    ofType<SetThermPoint>(EnumHomesActions.SetThermPoint),
    switchMap( (action) => {
      return this.homesService.setThermPoint(action.params).pipe(
        switchMap(() => [
          new SetThermPointSuccess(action.params)
        ]),
        catchError( ({error}) =>
          of(new SetThermPointFailure(error))
        )
      );
    })
  ));

  setHeatingModeMode$ = createEffect(() => this.actions$.pipe(
    ofType<SetHeatingMode>(EnumHomesActions.SetHeatingMode),
    switchMap((action) => {
      return this.homesService.setHomeData(action.payload).pipe(
        switchMap(() => [
          new SetHeatingModeSuccess(action.payload)
        ]),
        catchError(({ error }) =>
          of(new SetHeatingModeFailure(error))
        )
      );
    })
  ));

  setCoolingMode$ = createEffect(() => this.actions$.pipe(
    ofType<SetCoolingMode>(EnumHomesActions.SetCoolingMode),
    switchMap((action) => {
      return this.homesService.setHomeData(action.payload).pipe(
        switchMap(() => [
          new SetCoolingModeSuccess(action.payload)
        ]),
        catchError(({ error }) =>
          of(new SetCoolingModeFailure(error))
        )
      );
    })
  ));

  setAutoMode$ = createEffect(() => this.actions$.pipe(
    ofType<SetAutoMode>(EnumHomesActions.SetAutoMode),
    switchMap((action) => {
      return this.homesService.setHomeData(action.payload).pipe(
        switchMap(() => [
          new SetAutoModeSuccess(action.payload)
        ]),
        catchError(({ error }) =>
          of(new SetAutoModeFailure(error))
        )
      );
    })
  ));

  loadForecast$ = createEffect(() => this.actions$.pipe(
    ofType<LoadForecast>(EnumHomesActions.LoadForecast),
    switchMap((action) => {
      return this.homesService.loadForecast(action.payload).pipe(
        switchMap((res) => [
          new LoadForecastSuccess(res),
        ]),
        catchError(({ error }) =>
          of(new LoadForecastFailure(error))
        )
      );
    })
  ));

  loadComparisonData$ = createEffect(() => this.actions$.pipe(
    ofType<LoadComparisonData>(EnumHomesActions.LoadComparisonData),
    switchMap((action) => {
      return this.homesService.loadComparisonData(action.payload).pipe(
        switchMap((res) => [
          new LoadComparisonDataSuccess({...res, month: action.payload.month - 1, year: action.payload.year}), // month api starts from 1, moment starts at 0
        ]),
        catchError(({ error }) =>
          of(new LoadComparisonDataFailure(error))
        )
      );
    })
  ));
}

