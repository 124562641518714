import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { createSelector } from '@ngrx/store';
import { UserState } from './user.state';

const userState = (state: SettingsState) => state.user;

export const getUserEmail = createSelector(
  userState,
  (state: UserState) => {
    return state.mail;
  }
);

export const getUser = createSelector(
  userState,
  state => {
    return state;
  }
);

export const getUserAnalytics = createSelector(
  userState,
  state => {
    return state.app_telemetry;
  }
);

export const getLocale = createSelector(
  userState,
  state => {
    return state.locale;
  }
);

export const getKeychain = createSelector(
  userState,
  state => {
    return state.keychain;
  }
);

export const getUserId = createSelector(
  userState,
  state => {
    return state.id;
  }
);

type ConfigAlias = { name: string; files: string[]} | string;

interface LangConfig {
  locale: string;
  aliases: ConfigAlias[] | string;
  langs: {
    SupportedLanguage: {[key: string]: {[key: string]: string}};
    BackupLanguage: {[key: string]: {[key: string]: string}};
    DefaultLanguage: {[key: string]: string};
  };
}

interface  AliasWithLocale {
  name: string;
  locale: string;
}
type Aliases = (AliasWithLocale | string)[] | string;

/**
 * Return object describing the lang files to fetch
 * ### LangConfig
 * #### locale
 * - default prefix and default language
 *
 * #### aliases
 * - **String**: uses 'locale' as default prefix and default, language fetch given file
 * - **Array<string>**: uses 'locale' as default prefix and default, language fetch given files
 * - **Array<string | Object>**:
 *    - name: prefix and default language
 *    - files: files to fetch
 *
 * #### Example
 * ```
 * {
 *   locale: 'common',
 *     aliases: [
 *      'common-settings',
 *      'common-app'
 *       {
 *        name: 'app',
 *        files: [
 *          'camera-app',
 *          'camera-settings'
 *        ]
 *     }
 *   ]
 * }
 * ```
 */
export const handleLangByUser = createSelector(
  userState,
  (state: UserState, langConfig: LangConfig): {locale: string, aliases: Aliases} => {

    const Lang = langConfig.langs;

    const countryCode = state.language.match(/[A-z]+-/g)
      ? state.language.match(/[A-z]+-/g)[0].substr(0, 2)
      : state.language;

    const defaultLocale = Lang.SupportedLanguage[langConfig.locale][state.language]
      || Lang.BackupLanguage[langConfig.locale][countryCode]
      || Lang.DefaultLanguage[langConfig.locale];

    let aliases: Aliases;

    if (typeof langConfig.aliases === 'string') {
      // ex: aliases: 'common-settings'
      aliases = langConfig.aliases;
    }
    else {
      // ex: aliases: ['app', {name:'app', files: ['camera-app']]}]
      aliases = langConfig.aliases.reduce<(AliasWithLocale | string)[]>((acc, curr) => {
        // case curr = 'string'
        if (typeof curr === 'string') {
          return [...acc, curr];
        }
        else {
          // case curr = {name: 'app', files: ['camera-app']}

          // Find corresponding locale with given name
          const currLocale = Lang.SupportedLanguage[curr.name][state.language]
            || Lang.BackupLanguage[curr.name][countryCode]
            || Lang.DefaultLanguage[curr.name];

          // For each file output object like: {name: 'camera-app', locale: 'en-US'}
          return [...acc, ...curr.files.map(f => ({name: f, locale: currLocale}))];
        }
      }, []);
    }

    return { locale: defaultLocale, aliases };
  }
);
