import { ModuleData } from '@library/store/modules/modules.interface';
import { Action } from '@ngrx/store';
import { GetHomeConfigPayload, HomeCoachsData, HomeData, HomeSyncPayload, SetHomeConfigPayload } from '../homes/homes.interface';
import { APIResponseError } from '../interfaces/erros';
import {
  CreateRoom, GetMeasureCsvPayload, NewRoomCreated, RoomModulesUpdate, RoomNameUpdate, RoomOffsetUpdatePayload, SetTrueTemperaturePayload
} from './rooms.interface';

export enum EnumRoomsActions {
  GetRooms = '[Rooms] Get Rooms data',
  GetRoomsSuccess = '[Rooms] Get Rooms data success',
  GetRoomsFailure = '[Rooms] Get Rooms data failure',
  RoomSelected = '[Rooms] Set current Room ID',
  UpdateRoomsStatus = '[Rooms] update rooms status after homes status success',
  UpdateRoomsGetConfig = '[Rooms] update rooms after getconfig success',
  UpdateRoomsSetConfig = '[Rooms] update rooms config after set config',
  SetTrueTemperature = '[Room] Set room true temperature',
  SetTrueTemperatureSuccess = '[Room] Set true temperature success',
  SetTrueTemperatureFailure = '[Room] Set true temperature failure',
  GetMeasureCsv = '[Room] Get measure csv/xls',
  GetMeasureCsvSuccess = '[Room] Get measure csv/xls success',
  GetMeasureCsvFailure = '[Room] Get measure csv/xls failure',
  GetRoomMeasureCsv = '[Room] Get room measure csv/xls',
  GetRoomMeasureCsvSuccess = '[Room] Get room measure csv/xls success',
  GetRoomMeasureCsvFailure = '[Room] Get room measure csv/xls failure',
  GetRoomsHomeCoachsSuccess = '[Room] Get room Home Coachs success',
  GetRoomsHomeCoachsFailure = '[Room] Get room Home Coachs failure',

  UpdateRoomName = '[Room] Updating room name',
  UpdateRoomNameSuccess = '[Room] Updating room name success',
  UpdateRoomNameFailure = '[Room] Updating room name failure',

  RemoveModuleFromRoom = '[Room] Removing Module From Room',
  AddModuleToRoom = '[Room] Adding Module To Room',

  CreateNewRoom = '[Room] Creating New Room In Home',
  CreateNewRoomSuccess = '[Room] Successfully Created New Room In Home',
  CreateNewRoomFailure = '[Room] Failed To Create New Room In Home',

  DeleteMeasurements = '[Room] Delete measurements',
  DeleteMeasurementsSuccess = '[Room] Delete measurements success',
  DeleteMeasurementsFailure = '[Room] Delete measurements failure',

  DeleteRoom = '[Room] Deleting Room',
  DeleteRoomSuccess = '[Room] Successfully Deleted Room',
  DeleteRoomFailure = '[Room] Failed To Delete Room',

  UpdateRoomMeasureOffset = '[Room] Udating Room Mesaure Offset',
  UpdateRoomMeasureOffsetSuccess = '[Room] Successfully Update Room Measure Offset',
  UpdateRoomMeasureOffsetFailure = '[Room] Failed to Update Room Measure Offset'
}

export class GetRooms implements Action {
  public readonly type = EnumRoomsActions.GetRooms;
  constructor(public payload) { }
}

export class GetRoomsHomeCoachsSuccess implements Action {
  public readonly type = EnumRoomsActions.GetRoomsHomeCoachsSuccess;
  constructor(public payload: HomeCoachsData) {}
}

export class GetRoomsHomeCoachsFailure implements Action {
  public readonly type = EnumRoomsActions.GetRoomsHomeCoachsFailure;
  constructor(public payload) {}
}

export class SelectRoom implements Action {
  public readonly type = EnumRoomsActions.RoomSelected;
  constructor(public payload: string) { }
}

export class GetRoomsSuccess implements Action {
  public readonly type = EnumRoomsActions.GetRoomsSuccess;
  constructor(public payload: HomeData[], public modules?: ModuleData[]) { }
}
export class UpdateRoomsStatus implements Action {
  public readonly type = EnumRoomsActions.UpdateRoomsStatus;
  constructor(public payload: HomeSyncPayload) { }
}
export class GetRoomsFailure implements Action {
  public readonly type = EnumRoomsActions.GetRoomsFailure;
  constructor(public payload) { }
}
export class UpdateRoomsGetConfig {
  public readonly type = EnumRoomsActions.UpdateRoomsGetConfig;
  constructor(public payload: GetHomeConfigPayload) { }
}
export class UpdateRoomsSetConfig {
  public readonly type = EnumRoomsActions.UpdateRoomsSetConfig;
  constructor(public payload: SetHomeConfigPayload) { }
}

export class SetTrueTemperature implements Action {
  public readonly type = EnumRoomsActions.SetTrueTemperature;
  constructor(public payload: SetTrueTemperaturePayload) { }
}

export class SetTrueTemperatureSuccess implements Action {
  public readonly type = EnumRoomsActions.SetTrueTemperatureSuccess;
  constructor(public payload: SetTrueTemperaturePayload) { }
}

export class SetTrueTemperatureFailure implements Action {
  public readonly type = EnumRoomsActions.SetTrueTemperatureFailure;
  constructor(public payload) {}
}

export class GetMeasureCsv implements Action {
  public readonly type = EnumRoomsActions.GetMeasureCsv;
  constructor(public payload: GetMeasureCsvPayload) {}
}

export class GetMeasureCsvSuccess implements Action {
  public readonly type = EnumRoomsActions.GetMeasureCsvSuccess;
  constructor(public payload: Blob, public info: GetMeasureCsvPayload) {}
}

export class GetMeasureCsvFailure implements Action {
  public readonly type = EnumRoomsActions.GetMeasureCsvFailure;
  constructor(public payload) {}
}

export class GetRoomMeasureCsv implements Action {
  public readonly type = EnumRoomsActions.GetRoomMeasureCsv;
  constructor(public payload: GetMeasureCsvPayload) {}
}

export class GetRoomMeasureCsvSuccess implements Action {
  public readonly type = EnumRoomsActions.GetRoomMeasureCsvSuccess;
  constructor(public payload: Blob, public info: GetMeasureCsvPayload) {}
}

export class GetRoomMeasureCsvFailure implements Action {
  public readonly type = EnumRoomsActions.GetRoomMeasureCsvFailure;
  constructor(public payload) {}
}

export class UpdateRoomName implements Action {
  public readonly type = EnumRoomsActions.UpdateRoomName;
  constructor(public payload: RoomNameUpdate, public header?: { [key: string]: string }) {}
}

export class UpdateRoomNameSuccess implements Action {
  public readonly type = EnumRoomsActions.UpdateRoomNameSuccess;
  constructor(public payload: RoomNameUpdate) {}
}

export class UpdateRoomNameFailure implements Action {
  public readonly type = EnumRoomsActions.UpdateRoomNameFailure;
  constructor(public payload: APIResponseError ) {}
}

export class RemoveModuleFromRoom implements Action {
  public readonly type = EnumRoomsActions.RemoveModuleFromRoom;
  constructor(public payload: RoomModulesUpdate) {}
}

export class AddModuleToRoom implements Action {
  public readonly type = EnumRoomsActions.AddModuleToRoom;
  constructor(public payload: RoomModulesUpdate, public header?: { [key: string]: string }) {}
}

export class CreateNewRoom implements Action {
  public readonly type = EnumRoomsActions.CreateNewRoom;
  constructor(public payload: CreateRoom, public header?: { [key: string]: string }) {}
}

export class CreateNewRoomSuccess implements Action {
  public readonly type = EnumRoomsActions.CreateNewRoomSuccess;
  constructor(public payload: NewRoomCreated) {}
}

export class CreateNewRoomFailure implements Action {
  public readonly type = EnumRoomsActions.CreateNewRoomFailure;
  constructor(public payload: APIResponseError) {}
}

export class DeleteMeasurements implements Action {
  public readonly type = EnumRoomsActions.DeleteMeasurements;
  constructor(public payload) {}
}

export class DeleteMeasurementsSuccess implements Action {
  public readonly type = EnumRoomsActions.DeleteMeasurementsSuccess;
  constructor(public payload) {}
}

export class DeleteMeasurementsFailure implements Action {
  public readonly type = EnumRoomsActions.DeleteMeasurementsFailure;
  constructor(public payload) {}
}

export class DeleteRoom implements Action {
  public readonly type = EnumRoomsActions.DeleteRoom;
  constructor(public payload: {home_id: string, room_id: string}) {}
}

export class DeleteRoomSuccess implements Action {
  public readonly type = EnumRoomsActions.DeleteRoomSuccess;
  constructor(public payload: {home_id: string, room_id: string}) {}
}

export class DeleteRoomFailure implements Action {
  public readonly type = EnumRoomsActions.DeleteRoomFailure;
  constructor(public payload) {}
}

export class UpdateRoomMeasureOffset implements Action {
  public readonly type = EnumRoomsActions.UpdateRoomMeasureOffset;
  constructor(public payload: RoomOffsetUpdatePayload[]) {}
}

export class UpdateRoomMeasureOffsetSuccess implements Action {
  public readonly type = EnumRoomsActions.UpdateRoomMeasureOffsetSuccess;
  constructor(public payload: RoomOffsetUpdatePayload[]) {}
}

export class UpdateRoomMeasureOffsetFailure implements Action {
  public readonly type = EnumRoomsActions.UpdateRoomMeasureOffsetFailure;
  constructor(public payload) {}
}

export type RoomsActions = GetRooms
  | GetRoomsSuccess
  | GetRoomsFailure
  | SelectRoom
  | UpdateRoomsStatus
  | UpdateRoomsGetConfig
  | UpdateRoomsSetConfig
  | SetTrueTemperature
  | SetTrueTemperatureSuccess
  | SetTrueTemperatureFailure
  | GetMeasureCsv
  | GetMeasureCsvSuccess
  | GetMeasureCsvFailure
  | GetRoomMeasureCsv
  | GetRoomMeasureCsvFailure
  | GetRoomMeasureCsvSuccess
  | UpdateRoomName
  | UpdateRoomNameSuccess
  | UpdateRoomNameFailure
  | RemoveModuleFromRoom
  | AddModuleToRoom
  | CreateNewRoom
  | CreateNewRoomSuccess
  | CreateNewRoomFailure
  | DeleteMeasurements
  | DeleteMeasurementsSuccess
  | DeleteMeasurementsFailure
  | DeleteRoom
  | DeleteRoomSuccess
  | DeleteRoomFailure
  | UpdateRoomMeasureOffset
  | UpdateRoomMeasureOffsetSuccess
  | UpdateRoomMeasureOffsetFailure
  | GetRoomsHomeCoachsSuccess
  | GetRoomsHomeCoachsFailure
  ;
