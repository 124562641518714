import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LngLat, Map, MapOptions, Marker, MarkerOptions } from 'maplibre-gl';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FeatureProperties, JawgPlacesResponse } from '../interfaces/jawgs-api.interface';
import { PlaceInfos } from '../interfaces/place-infos.interface';
import { LangPipe } from '../pipes/lang.pipe';

// set 'jawgAccessToken: value' in environment file
export const JAWG_ACCESS_TOKEN = new InjectionToken('Access Token For Jawg API');

@Injectable()
export class JawgMapsService {
  private jawgBaseApi = 'https://api.jawg.io/places/v1';

  constructor(
    private langPipe: LangPipe,
    private http: HttpClient,
    @Inject(JAWG_ACCESS_TOKEN) private jawgAccessToken: string,
  ) {}

  /**
   * Gets a formatted address string from coordinates by calling the Jawg reverse geocoding API
   * @param coordinates The coordinates (longitude/latitude) to get the address for
   * @returns Observable that emits a formatted address string. If no results found, returns localized "no geolocation" message
   */
  getAddressByCoordinates(coordinates: LngLat): Observable<string> {
    return this.http.get<JawgPlacesResponse>(`${this.jawgBaseApi}/reverse?point.lat=${coordinates.lat}&point.lon=${coordinates.lng}&access-token=${this.jawgAccessToken}`).pipe(
      map((res) => {
        let address = this.langPipe.transform('common-settings.__NO_GEOLOCATION');
        if (res.features.length > 0) {
          address = this.getFormattedAddress(res.features[0].properties);
        }
        return address;
      }),
      catchError(() => {
        const errorMessage = this.langPipe.transform('common-app.__ERROR_UNKNOWN');
        return of(errorMessage);
      })
    );
  }

  getPlacesByText(text: string): Observable<PlaceInfos[]> {
    return this.http.get<JawgPlacesResponse>(`${this.jawgBaseApi}/autocomplete?text=${encodeURIComponent(text)}&size=5&access-token=${this.jawgAccessToken}`).pipe(
      map((placeInfosRequest) => {
        return placeInfosRequest.features.map((feature) => {
          const address = this.getFormattedAddress(feature.properties);
          const coordinates = new LngLat(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);

          return {
            address,
            coordinates,
          };
        });
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  private getFormattedAddress(props: FeatureProperties): string {
    return [props.name, props.locality, props.postalcode, props.country].filter(Boolean).join(', ');
  }

  createMap(mapId: string, options?: Partial<MapOptions>): Map {
    const defaultOptions: MapOptions = {
      container: mapId,
      style: `https://api.jawg.io/styles/7c47e08c-62b8-4523-a265-32267d4f8e9b.json?access-token=${this.jawgAccessToken}`,
      center: new LngLat(0, 0),
      keyboard: true,
      minZoom: 3,
      zoom: 3,
      pitchWithRotate: false,
    };

    return new Map({ ...defaultOptions, ...options });
  }

  createMarker(coordinates: LngLat, options?: Partial<MarkerOptions>): Marker {
    const element = document.createElement('div');
    element.style.backgroundImage = `url('assets/images/maps/location-marker.png')`;
    element.style.width = '32px';
    element.style.height = '45px';
    element.style.backgroundSize = 'contain';

    const markerOptions: MarkerOptions = {
      element,
      anchor: 'bottom',
      draggable: true,
      ...options,
    };

    return new Marker(markerOptions)
      .setLngLat(coordinates);
  }
}
