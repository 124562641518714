import { Module, ModuleConfig } from '../modules/modules.interface';
import { Room, RoomConfig } from '../rooms/rooms.interface';
import { Schedule } from '../schedules/schedules.interface';
import { User } from '../user/user.interface';

export interface HomesData {
  homes: HomeData[];
  modules: Module[];
  user: User;
}

export interface HomeCoach {
    _id: string;
    station_name: string;
    name: string;
    type: string;
    subtype?: string;
    last_status_store: number;
    last_setup: number;
    firmware: number;
    last_upgrade?: number;
    hardware_version?: number;
    date_setup: number;
    wifi_status: number;
    behavior: number;
    user_owner: string[];
    customer_id?: string;
    co2_calibrating: boolean;
    admin_access_code: string[];
    advice: string;
    data_type: string[];
    dashboard_data?: {
      AbsolutePressure: number;
      CO2: number;
      Humidity: number;
      Noise: number;
      Pressure: number;
      Temperature: number;
      health_idx: number;
      time_utc: number
    },
    reachable: boolean;
    profile: string;
    place: {
      altitude: number,
      city: string,
      country: string;
      location: number[]
      street: string;
      timezone: string
    },
    alarm_config: {
      default_alarm?: [],
      personnalized:
      {
        threshold: number;
        data_type: number;
        direction: number;
        default: number;
        db_alarm_number: number
      }[]
    },
    co2_abc_status?: string,
    co2_abc_order?: string,

}

export interface HomeCoachsData {
  devices: HomeCoach[],
  user: {
    mail: string,
    administrative: {
      country: string,
      feel_like_algo: number,
      lang: string,
      pressureunit: number,
      reg_locale: string,
      unit: number,
      windunit: number
    },
    app_telemetry: boolean
  },
  status: string,
  time_exec: number,
  time_server: number
}

// Type of error returned by SyncAPI
export interface SyncError {
  code: number;
  id: string;
}

export interface Home {
  status_loaded?: boolean;
  config_loaded?: boolean;
  admin_access_code: string;
  error: string;
  id: string;
  modules: string[];
  name: string;
  place: Place;
  rooms: string[];
  users: User[];
  schedules?: Schedule[];
  read_only?: boolean;
  temperature_control_mode?: string;
  capabilities?: Capability[];
  persons?: Persons;
  home_type?: string | undefined;
  share_info?: boolean;
  therm_mode?: string;
  therm_mode_endtime?: number;
  cooling_mode?: string;
  cooling_mode_endtime?: number;
  auto_temp_mode?: string;
  auto_mode_endtime?: number;
  mode?: 'friend' | 'favorite' | 'homeCoach';
  therm_setpoint_default_duration?: number;
}

export interface HomeData {
  altitude: number;
  anticipation: boolean;
  coordinates: [number, number];
  country: string;
  currency_code: string;
  home_size: number;
  home_type: string;
  id: string;
  invitation_code: string[];
  modules: Module[];
  name: string;
  nb_users: number;
  open_window_detection: boolean;
  outdoor_temperature_source: string;
  rooms: Room[];
  schedules: Schedule[];
  therm_absence_autoaway: boolean;
  therm_absence_notification: boolean;
  therm_heating_energy: string;
  therm_heating_priority: string;
  therm_mode: string;
  therm_setpoint_default_duration: number;
  timezone: string;
  read_only?: boolean;
  capabilities: Capability[];
}

export interface Capability {
  name: string;
  available: boolean;
}
export interface Place {
  coordinates?: number[];
  location?: number[];
  altitude: number;
  country: string;
  timezone?: string;
  address?: string;
}

export interface PlaceUpdate {
  home_id: string;
  lat?: number;
  lon?: number;
  safe?: boolean;
  tz?: string;
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface GetHomeInfoPayload {
  home_id?: string;
  device_types?: string | string[];
  module_ids?: string[]
}

export interface SetHomeConfigPayload {
  home_id: string;
  home: { modules?: ModuleConfig[], rooms?: RoomConfig[] };
}

export interface SetStatePayload {
  home: {
    id: string,
    rooms?: {
      id: string,
      target_bridges?: string[],
      state?: 'remote_created'[],
      local_id?: string
    }[],
    modules?: {
      id: string,
      reset?: string,
      target_bridges?: string[],
      state?: 'remote_assigned'[],
      room_id?: string
    }[]
  };
}

export interface AckLocalResourcesPayload {
  home_id: string;
  device_id: string;
  rooms?: {
    id: string;
    state?: "remote_created"[];
    local_id?: string;
  }[];
  modules?: {
    id: string;
    reset?: string;
    state?: "remote_assigned"[];
    room_id?: string;
  }[];
}

// Type of data returned by getHomeStatus
export interface HomeSyncPayload {
  id: string;
  modules?: Module[];
  rooms?: Room[];
}

// Type of data returned by getHomeStatus
export interface GetHomeConfigPayload {
  id: string;
  modules?: ModuleConfig[];
  rooms?: RoomConfig[];
}

export interface HomesNameUpdate {
  home_id: string;
  name: string;
}

export interface Person {
  id: string;
  url: string;
  pseudo?: string;
}

export type Persons = Person[];

export const thermoregulationDevices = ['NATherm1', 'NRV', 'BNS', 'OTM', 'BAC', 'NAC', 'NAThermVaillant', 'BNTH', 'BIRE']; // !! Don't forget NLC with applicance type radiator

export enum TemperatureControlModes {
  HEATING = 'heating',
  COOLING = 'cooling',
  AUTO = 'auto',
}

export enum ThermModes {
  SCHEDULE = 'schedule',
  FROSTGUARD = 'hg',
  AWAY = 'away',
  OFF = 'off',
}

export enum AutoTempModes {
  SCHEDULE = 'schedule',
  OFF = 'off',
  AWAY = 'away',
}

export enum CoolingModes {
  SCHEDULE = 'schedule',
  OFF = 'off',
  AWAY = 'away',
}

export interface LoadForecastPayload {
  device_id: string;
  module_id: string;
  locale: string;
  ask_graphs: AskGraphs[];
}

export enum AskGraphs {
  RAIN = 'rain',
  WIND = 'wind',
  TEMPERATURE = 'temperature',
  RAIN_PROBA = 'rain_proba',
  HUMIDITY = 'humidity',
  SYMBOL = 'symbol',
}

export interface LoadForecastSuccessPayload {
}

export interface LoadForecastSuccessPayload {
  forecastDays: ForecastDay[];
  time_day_begin: number;
  time_day_end: number;
  current_windgust: number;
  current_windstrength: number;
  time_current: number;
  cityname: string;
  current_symbol: string;
  time_current_symbol: number;
  current_temp: number;
  current_temp_time: number;
  location: Location;
  fromCache: boolean;
  stationname: string;
  last_time_ask: number;
  airqdata: Airqdata;
  airqdatav2: Airqdatav2;
  forecastGraphs: ForecastGraphs;
}

export type ForecastGraphs = Record<AskGraphs, [number, number][]>;

export interface Airqdata {
  maxscale: number;
  maxgauge: number;
  paramName: string;
  source: string;
  type: Type[];
  data: {
    beg_time: number;
    value: Array<Array<number | string>>
  }[];
  fromCache: boolean;
}

export interface Datum {
  beg_time: number;
  value: Array<Array<number | string>>;
}

export interface Type {
  type_idx: string;
  data: string[];
}

export interface Airqdatav2 {
  sources: string[];
  pollutants: Pollutant[];
}

export interface Pollutant {
  type: string;
  aqi: number;
  category: string;
}

export interface ForecastDay {
  day_ts: number;
  min_temp: number;
  max_temp: number;
  sunset: number;
  sunrise: number;
  rain: number;
  uv: number;
  windstrength: number;
  windgust: number;
  windangle: number;
  winddirection: number;
  wind_direction: number;
  weather_symbol_day: string;
  day_locale: string;
  sun?: number;
}

export interface Location {
  lat: string;
  lon: string;
}

export interface LoadComparisonDataPayload {
  home_id: string;
  gateway_id: string;
  month: number;
  year: number;
}

export interface LoadComparisonDataSuccessPayload {
  month: number;
  year: number;
  house_comparison: {
    heating_time_similar_houses_points: { x: number, y: number }[];
    tbo_50_percent: number;
    tbo_75_percent: number;
  };
  tbo_saving: number;
  home_size: number;
}