import { Injectable, InjectionToken } from '@angular/core';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GetUser, GetUserSuccess } from './user.action';
import * as UserSelectors from './user.selector';

export const USER_FACADE = new InjectionToken('USER_FACADE');
@Injectable()
export class UserFacade {
  currentUser$ = this.store.pipe(select(UserSelectors.getUser));
  userMail$ = this.store.pipe(select(UserSelectors.getUserEmail));
  locale$ = this.store.pipe(select(UserSelectors.getLocale));
  keychain$ = this.store.pipe(select(UserSelectors.getKeychain));
  currentUserId$ = this.store.pipe(select(UserSelectors.getUserId));
  
  constructor(
        protected store: Store<SettingsState>,
        protected actions$: Actions,
  ) { }

  /**
     * Loads data of the user
     * @returns Observable on action of type GetUserSuccess when it is fired
     */
  loadUser(): Observable<Action> {
    this.store.dispatch(GetUser());

    return this.actions$.pipe(
      ofType(GetUserSuccess),
      take(1)
    );
  }
}
